import * as T from "@/publicType/publicType";

const state: T.TypeState = {
	assortProducId: "",
	assortCaseId: "",
};

const getters = {
	getAssortProducId(state: T.TypeState) {
		return state.assortProducId;
	},
	getAssortCaseId(state: T.TypeState) {
		return state.assortCaseId;
	},
};

const mutations = {
	assortProducIdSyn: (state: T.TypeState, data: string) => {
		state.assortProducId = data;
	},
	assortCaseIdSyn: (state: T.TypeState, data: string) => {
		state.assortCaseId = data;
	},
};

const actions = {
	assortProducIdModify(context: T.TypeContext, data: string) {
		context.commit("assortProducIdSyn", data);
	},
	assortCaseIdModify(context: T.TypeContext, data: string) {
		context.commit("assortCaseIdSyn", data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
