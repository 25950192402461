import index from "./index";
import login from "./login";
import user from "./user";
import company from "./company";
import banner from "./banner";
import produc from "./produc";
import Case from "./case";
import about from "./about";
import recruit from "./recruit";
import assort from "./assort";

export default {
	...index,
	...login,
	...user,
	...company,
	...banner,
	...produc,
	...Case,
	...about,
	...recruit,
	...assort,
};
