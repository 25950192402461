import { deepClone } from "@/utils/index";
import * as T from "@/publicType/publicType";
import API from "@/api/appApi";
import { da } from "element-plus/es/locale";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { date } from "@/utils/index";

const state: T.TypeState = {
	navigationClick: "/index", //标签页值
	bannerList: [],
	aboutList: [],
	caseList: [],
	companyInfo: {}, //公司详情
	assortList: [],
};

const getters = {
	getNavigationClick(state: T.TypeState) {
		return state.navigationClick;
	},
};

const mutations = {
	navigationClickSyn: (state: T.TypeState, data: string) => {
		state.navigationClick = data;
	},
	bannerListSyn: (state: T.TypeState, data: any[]) => {
		state.bannerList = data;
	},
	aboutListSyn: (state: T.TypeState, data: any[]) => {
		state.aboutList = data;
	},
	caseListSyn: (state: T.TypeState, data: any[]) => {
		state.caseList = data;
	},
	assortListSyn: (state: T.TypeState, data: any[]) => {
		state.assortList = data;
	},

	companyInfoSyn: (state: T.TypeState, data: T.TcompanyInfo) => {
		state.companyInfo = data;
	},
};

const actions = {
	navigationClickModify(context: T.TypeContext, data: string) {
		context.commit("navigationClickSyn", data);
	},

	//	初始化
	initModify(context: T.TypeContext, data = false) {
		return new Promise((resolve, reject) => {
			if (context.state.bannerList?.length && !data && getToken("WEcacheTime")) {
				resolve(context.state);
			} else {
				Promise.all([
					API.BannerGetList({
						page: 1,
						pageSize: 100,
					}),
					API.CompanyGetInfo({
						pageIndex: 1,
						pageSize: 1,
					}),
					API.AboutGetList({
						page: 1,
						pageSize: 100,
					}),
					API.CaseGetList({
						page: 1,
						pageSize: 6,
						inIndex: 1,
					}),
					API.AssortGetList({
						page: 1,
						pageSize: 100,
					}),
				]).then((res) => {
					const inFifteenMinutes = new Date(date((new Date().getTime() + 24 * 60 * 60 * 1000) as any as string));
					setToken("WEcacheTime", 1, inFifteenMinutes);

					context.commit("bannerListSyn", (res[0] as any).list);
					context.commit("aboutListSyn", (res[2] as any).list);
					context.commit("caseListSyn", (res[3] as any).list);
					context.commit("assortListSyn", (res[4] as any).list);
					if ((res[1] as any).list[0]) {
						context.commit("companyInfoSyn", (res[1] as any).list[0]);
					}
					resolve(context.state);
				});
			}
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
