import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import login from "@/views/login/index.vue";
import Navigation from "@/components/navigation/index.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/loading",
	},
	{
		path: "/loading",
		name: "loading",
		component: () => import("@/views/loading.vue"),
	},
	{
		path: "/index",
		name: "index",
		component: Navigation,
		children: [
			{
				path: "",
				name: "首页",
				component: () => import("@/views/index/index.vue"),
			},
		],
	},
	{
		path: "/about",
		name: "about",
		component: Navigation,
		children: [
			{
				path: "index",
				name: "关于",
				component: () => import("@/views/about/index.vue"),
			},
		],
	},
	{
		path: "/produc",
		name: "produc",
		component: Navigation,
		children: [
			{
				path: "index",
				name: "产品",
				component: () => import("@/views/produc/index.vue"),
			},
			{
				path: "details",
				name: "产品详情",
				meta: {
					keepAlive: true, // 不需要被缓存
				},
				component: () => import("@/views/produc/details.vue"),
			},
		],
	},
	{
		path: "/case",
		name: "case",
		component: Navigation,
		children: [
			{
				path: "index",
				name: "案例",
				component: () => import("@/views/case/index.vue"),
			},
			{
				path: "details",
				name: "案例详情",
				meta: {
					keepAlive: true, // 不需要被缓存
				},
				component: () => import("@/views/case/details.vue"),
			},
		],
	},
	{
		path: "/contact",
		name: "contact",
		component: Navigation,
		children: [
			{
				path: "index",
				name: "联系我们",
				component: () => import("@/views/contact/index.vue"),
			},
		],
	},

	// {
	// 	path: "/404",
	// 	component: () => import("@/views/404.vue"),
	// },
	{ path: "/:pathMatch(.*)", redirect: "/loading" },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
