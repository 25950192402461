import API from "@/api/appApi";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { encode, decode } from "js-base64";
import router from "@/router/index";
import { date } from "@/utils/index";
import md5 from "js-md5";

export function refreshToken() {
	const data = {
		username: "website",
		password: md5("website"),
	};
	API.Login(data)
		.then((res) => {
			if (res && (res as { token: string }).token) {
				const inFifteenMinutes = new Date(date((new Date().getTime() + 1 * 60 * 60 * 1000) as any as string));
				setToken("WEtoken", (res as { token: string }).token, inFifteenMinutes);
				setToken("WEtokenTime", new Date().getTime(), inFifteenMinutes);
			}
		})
		.catch((err) => {
			console.log(err);
		});
}
