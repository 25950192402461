import { createStore } from "vuex";
import index from "./modules/index";
import assort from "./modules/assort";

import createPersistedState from "vuex-persistedstate";

export default createStore({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		index,
		assort,
	},
	plugins: [createPersistedState()],
});
