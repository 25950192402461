import request from "@/request/request";
import * as T from "@/publicType/publicType";

export default {
	GetLeftMenu(data: object | null = null) {
		return request.post("/Home/GetLeftMenu", data);
	},
	GetUser(data: object | null = null) {
		return request.post("/Home/GetUserInfo", data);
	},
};
