const remConfig = () => {
	//创建配置函数
	let deviceWidth = document.documentElement.clientWidth; //声明变量使得等于设备宽度
	if (deviceWidth > 1920) {
		deviceWidth = 1920;
	} else if (deviceWidth < 980) {
		deviceWidth = 980;
	}

	document.documentElement.style.fontSize = deviceWidth / 1920 + "px"; //设置fontSize为1920设备宽度大小
};
window.onresize = function () {
	remConfig();
	//当窗口发生变化时，重新调用remConfig;此步骤可省略，因为移动端一般没有设备大小，便于在chrome调试
};
export default remConfig; //导出
