import request from "@/request/request";
import * as T from "@/publicType/publicType";

export default {
	ProductGetList(data: object | null = null) {
		return request.post("/Site/Product/GetList", data);
	},
	ProductInsert(data: object | null = null) {
		return request.post("/Site/Product/Insert", data);
	},
	ProductGetInfo(data: object | null = null) {
		return request.posts("/Site/Product/GetInfo", data);
	},
	ProductDelete(data: object | null = null) {
		return request.posts("/Site/Product/Delete", data);
	},
	ProductUpdate(data: object | null = null) {
		return request.post("/Site/Product/Update", data);
	},
};
