import request from "@/request/request";
import * as T from "@/publicType/publicType";

export default {
	RecruitGetList(data: object | null = null) {
		return request.post("/Site/Recruit/GetList", data);
	},
	RecruitInsert(data: object | null = null) {
		return request.post("/Site/Recruit/Insert", data);
	},
	RecruitGetInfo(data: object | null = null) {
		return request.posts("/Site/Recruit/GetInfo", data);
	},
	RecruitDelete(data: object | null = null) {
		return request.posts("/Site/Recruit/Delete", data);
	},
	RecruitUpdate(data: object | null = null) {
		return request.post("/Site/Recruit/Update", data);
	},
};
