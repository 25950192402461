import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/index";
import store from "./store/index";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

import "@/assets/css/index.scss";
import "@/request/header";
import "@/request/request";
import "@/api/appApi";
const app = createApp(App);
//设置rem相关配置
import remConfig from "@/utils/rem";
remConfig();

// 导入SvgIcon组件
import SvgIcon from "@/components/SvgIcon/index.vue";
const req = require.context("@/icons/svg", false, /\.svg$/);
req.keys().map(req);
app.component("svg-icon", SvgIcon);

app.use(ElementPlus, {
	locale: zhCn, // 汉化
});
app.use(store);
app.use(router);
app.mount("#app");

router.push("/loading");
//router.push("/index");

/*
后置路由守卫
路由跳转除了 '/login', '/404'
采用 store.commit('index/tabsListValueSyn', '路由地址')
激活标签页，触发标签页事件跳转页面，避免多次跳转
 */
router.beforeEach((to, from) => {
	const noList = ["/loading", "/404"];
	if (noList.indexOf(to.path) === -1) {
		// 添加标签栏
		store.dispatch("index/navigationClickModify", to.path);
	}
});
