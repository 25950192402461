import axios from "axios";
import { TypeMethods } from "@/publicType/publicType";
import localData from "@/localData/data";
const state = false;//process.env.VUE_APP_LOCAL_DATA;

interface lSType {
	[key: string]: object;
}

//对外接口
class Request {
	get(url: string, params: any = null) {
		return this.methods("get", url, { params: params }, null, null);
	}

	post(url: string, params: any | null = null, time: number | null = null) {
		const headerConfig = {
			"Content-Type": "application/json",
		};
		return this.methods("post", url, JSON.stringify(params), headerConfig, time);
	}

	posts(url: string, params: any = null) {
		if (JSON.stringify(params) !== "{}" && params !== null) {
			url = url + "?";
			for (const key in params) {
				url = url + key + "=" + params[key] + "&";
			}
			url = url.substring(0, url.length - 1);
		}
		const headerConfig = {
			"Content-Type": "application/json",
		};
		return this.methods("post", url, null, headerConfig, null);
	}

	methods(method: TypeMethods, url: string, params: any = null, headerConfig: any = null, time: number | null = null) {
		// const loadingInstance = ElLoading.service({
		//     lock: true,
		//     text: 'Loading',
		// })

		return new Promise((resolve, reject) => {
			if (state) {
				let newUrl: string = url;
				if (method == "post") {
					params = JSON.parse(params);
					if (JSON.stringify(params) !== "{}" && params !== null) {
						newUrl = newUrl + "?";
						for (const key in params) {
							newUrl = newUrl + key + "=" + params[key] + "&";
						}
						newUrl = newUrl.substring(0, newUrl.length - 1);
					}
				}
				resolve(localData[newUrl]);
			} else {
				axios[method](url, params, { headers: headerConfig, timeout: time ? time : 5000 })
					.then((res: any) => {
						// loadingInstance.close()

						if (state) {
							// 生成缓存数据
							// localStorage.setItem
							// localStorage.getItem
							let newUrl: string = url;
							if (method == "post") {
								params = JSON.parse(params);
								if (JSON.stringify(params) !== "{}" && params !== null) {
									newUrl = newUrl + "?";
									for (const key in params) {
										newUrl = newUrl + key + "=" + params[key] + "&";
									}
									newUrl = newUrl.substring(0, newUrl.length - 1);
								}
							}
							const lStorage = localStorage.getItem("localData");

							let newStorage: lSType = {};
							if (lStorage) {
								newStorage = JSON.parse(lStorage);
							}
							newStorage[newUrl] = res;
							localStorage.setItem("localData", JSON.stringify(newStorage));
						}

						resolve(res);
					})
					.catch((err: any) => {
						// loadingInstance.close()
						reject(err);
					});
			}
		});
	}
}

export default new Request();
