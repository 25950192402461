import request from "@/request/request";
import * as T from "@/publicType/publicType";

export default {
	CaseGetList(data: object | null = null) {
		return request.post("/Site/Case/GetList", data);
	},
	CaseInsert(data: object | null = null) {
		return request.post("/Site/Case/Insert", data);
	},
	CaseGetInfo(data: object | null = null) {
		return request.posts("/Site/Case/GetInfo", data);
	},
	CaseDelete(data: object | null = null) {
		return request.posts("/Site/Case/Delete", data);
	},
	CaseUpdate(data: object | null = null) {
		return request.post("/Site/Case/Update", data);
	},
};
