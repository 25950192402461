import request from "@/request/request";
import * as T from "@/publicType/publicType";

export default {
	CompanyGetInfo(data: object | null = null) {
		return request.post("/Site/Company/GetList", data);
	},
	CompanyUpdate(data: object | null) {
		return request.post("/Site/Company/Update", data);
	},
	CompanyInsert(data: object | null) {
		return request.post("/Site/Company/Insert", data);
	},
};
