<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script setup lang="ts"></script>
<style lang="scss">
	* {
		margin: 0;
		padding: 0;
	}
	:deep(.tox-tinymce-aux) {
		z-index: 9999 !important;
	}
</style>
