import request from "@/request/request";
import * as T from "@/publicType/publicType";

export default {
	BannerGetList(data: object | null = null) {
		return request.post("/Site/Banner/GetList", data);
	},
	BannerInsert(data: object | null = null) {
		return request.post("/Site/Banner/Insert", data);
	},
	BannerDelete(data: object | null = null) {
		return request.posts("/Site/Banner/Delete", data);
	},
	BannerUpdate(data: object | null = null) {
		return request.post("/Site/Banner/Update", data);
	},
};
