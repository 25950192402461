import request from "@/request/request";
import * as T from "@/publicType/publicType";

export default {
	AssortGetList(data: object | null = null) {
		return request.post("/Site/Assort/GetList", data);
	},
	AssortInsert(data: object | null = null) {
		return request.post("/Site/Assort/Insert", data);
	},
	AssortGetInfo(data: object | null = null) {
		return request.posts("/Site/Assort/GetInfo", data);
	},
	AssortDelete(data: object | null = null) {
		return request.posts("/Site/Assort/Delete", data);
	},
	AssortUpdate(data: object | null = null) {
		return request.post("/Site/Assort/Update", data);
	},
};
