import request from "@/request/request";
import * as T from "@/publicType/publicType";

export default {
	AboutGetList(data: object | null = null) {
		return request.post("/Site/About/GetList", data);
	},
	AboutInsert(data: object | null = null) {
		return request.post("/Site/About/Insert", data);
	},
	AboutGetInfo(data: object | null = null) {
		return request.posts("/Site/About/GetInfo", data);
	},
	AboutDelete(data: object | null = null) {
		return request.posts("/Site/About/Delete", data);
	},
	AboutUpdate(data: object | null = null) {
		return request.post("/Site/About/Update", data);
	},
};
